<template>
  <b-card class="mb-4" header-bg-variant="custom" no-body>
    <template #header>
      <h4 class="text-white">{{ title }}</h4>
    </template>
    <b-card-body>
      <b-row>
        <b-col class="col-12" v-if="period_info">
          <p class="text-muted">{{ period_info }}</p>
        </b-col>
        <b-col class="col-12 table-responsive">
          <b-table
            class="small provenance-table"
            sticky-header
            small
            striped
            caption-top
            :items="this.table"
            :fields="header"
            :per-page="per_page"
            :current-page="current_page"
          >
            <!-- formatting for flag -->
            <template v-slot:cell(flag)="data">
              <gb-flag :code="data.value" />
            </template>

            <!-- formatting for visitors -->
            <template v-slot:cell(visitors)="data">
              {{ data.value | formatNumber }}
            </template>

            <!-- formatting for overnights -->
            <template v-slot:cell(overnights)="data">
              {{ data.value | formatNumber }}
            </template>

            <!-- formatting for evolution -->
            <template v-slot:cell(evolution)="data">
              <p
                class="mb-1 font-weight-bold"
                :class="
                  $utils.isPositive(
                    data.item.overnights,
                    data.item.overnigthsBef
                  )
                    ? 'text-success'
                    : 'text-danger'
                "
              >
                <font-awesome-icon
                  :icon="
                    $utils.isPositive(
                      data.item.overnights,
                      data.item.overnigthsBef
                    )
                      ? 'angle-up'
                      : 'angle-down'
                  "
                />
                {{ data.value | formatPercent }}
              </p>
            </template>

            <!-- formatting for evolution_real -->
            <template v-slot:cell(evolution_real)="data">
              {{ data.value | formatNumber }}
            </template>

            <!-- formatting for visitors evo -->
            <template v-slot:cell(visitors_evo)="data">
              <span :class="data.value >= 0 ? 'text-success' : 'text-danger'">
                {{ data.value | formatPercent }}
              </span>
            </template>

            <!-- formatting for market share -->
            <template v-slot:cell(market_share)="data">
              {{ data.value | formatPercent }}
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <b-pagination
            v-model="current_page"
            :total-rows="rows"
            :per-page="per_page"
            size="sm"
            first-number
            last-number
            pills
          />
        </b-col>
      </b-row>
      <b-row v-if="this.sub_title">
        <b-col>
          <b-button
            v-if="!this.show_sub"
            variant="primary"
            @click="show_sub = !show_sub"
          >
            <font-awesome-icon :icon="['fas', 'plus']" />
            {{ this.sub_title }}</b-button
          >
          <b-button v-else variant="primary" @click="show_sub = !show_sub">
            <font-awesome-icon :icon="['fas', 'minus']" />
            {{ $t("general.hide_details") }}</b-button
          >
        </b-col>
      </b-row>
      <b-row v-if="this.show_sub">
        <b-col class="table-responsive">
          <b-table
            striped
            hover
            :items="this.sub_table"
            :fields="this.sub_header"
          >
            <!-- formatting for visitors -->
            <template v-slot:cell(visitors)="data">
              {{ data.value | formatNumber }}
            </template>

            <template v-slot:cell(flag)="data">
              <gb-flag :code="data.value" />
            </template>

            <template v-slot:cell(market_share)="data">
              {{ data.value | formatPercent }}
            </template>

            <!-- formatting for visitors evo -->
            <template v-slot:cell(visitors_evo)="data">
              <span :class="data.value >= 0 ? 'text-success' : 'text-danger'">
                {{ data.value | formatPercent }}
              </span>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    table: { type: Array, required: true },
    header: { type: Array, required: true },
    sub_title: { type: String, default: null },
    sub_table: { type: Array, default: null },
    sub_header: { type: Array, default: null },
    pagination: { type: Boolean, default: false },
    period_info: { type: String, defaul: null },
  },
  data() {
    return {
      show_sub: false,
      per_page: 25,
      current_page: 1,
    };
  },
  computed: {
    rows() {
      return this.table.length;
    },
  },
};
</script>
